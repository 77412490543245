@keyframes growDots {
    0%, 100% {
        content: '...';

    }

    25%, 75% {
        content: '..';
    }

    50% {
        content: '.';
    }
}

@keyframes showpartitions {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.global_settings {
    background-color: #F7F8F9;
    padding: 10px 20px 5px 20px;
    margin-bottom: 10px;
}

.global_settings input {
    background-color: #F7F8F9;
}

.title_global_settings {
    margin-bottom: 10px;
    padding-left: 20px;
    font-size: 20px;
    background-color: #e9e9e9;
    height: auto;
    padding-top: 9px;
    padding-bottom: 9px;
}

.title_global_settings:has(.statusLoading) {
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    position: relative;
}

.statusLoading {
    animation: glowing 1s linear infinite;
    background-color: rgb(0, 190, 0);
    font-weight: 600;
    color: white;
    padding: 2px 8px;
    border-radius: 20px;
    position: absolute;
    right: 10px;
    min-width: 250px;
}

@keyframes glowing {
    0%, 100% {
        opacity: 1;
    }

    50% {
        opacity: .1;
    }
}

.statusLoading::after {
    content: '...';
    animation: growDots 1s linear infinite;
}


.main_partitions {
    opacity: 0;
    animation: 0.2s showpartitions ease-in-out forwards;
    display: flex;
    flex-flow: column nowrap;
    gap: 20px;
}

.loading_main {
    width: 100%;
    height: 300px;
}