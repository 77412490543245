.statistics_wrapper {
    display: flex;
    flex-flow: column nowrap;
    width: 100%;
    gap: 10px;
    padding: 0 0 20px 0;
}

.head {
    margin-bottom: 10px;
    padding-left: 20px;
    font-size: 20px;
    background-color: #e9e9e9;
    height: auto;
    padding-top: 9px;
    padding-bottom: 9px;
}

.row {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    padding: 0 20px;
}

.statistics_item {
    background-color: #465A65;
    color: #fff;
    font-weight: 600;
    width: fit-content;
    padding: 0 0 0 8px;
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    border-radius: 0;
}

.statistics_item>* {
    padding: 10px 0;
}

.statistics_item .heading {
    text-align: center;
}

.statistics_item .value {
    color: #000000;
    background-color: #fff;
    border-radius: 5px;
    outline: 3px solid #000000;
    display: block;
    min-width: 35px;
    padding: 10px 5px;
    text-align: center;
}