@media screen and (max-width:640px) {
    .buttons_create {
        right: 10px !important;
        bottom: 10px !important;
    }
}

/* Отправка/очистка */
.buttons_create {
    position: fixed;
    right: 60px;
    bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: right;
    z-index: 24;
}

.container_clear_send {
    display: flex;
    flex-direction: row;
}


.container_clear_send a {
    mask-repeat: no-repeat;
    mask-position: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
    text-decoration-color: white;
    margin-top: 10px;
    border-radius: 50%;
}

.container_clear_send a:hover {
    background-color: rgba(0, 0, 0, 0.74);
    transform: scale(1.3);
    animation: none;
}

.container_clear_send .send {
    margin-left: 10px;
    background: rgba(17, 209, 120, 1);
    background: linear-gradient(45deg, rgb(0, 158, 84) 50%, rgb(0, 228, 121)50%);
    mask-image: var(--i_checkmark);
    mask-size: contain;
}

.container_clear_send .clear {
    background-color: rgb(221, 23, 9);
    background: linear-gradient(45deg, rgb(194, 13, 0) 50%, rgb(255, 17, 0) 50%);
    mask-image: var(--i_cross);
    mask-size: 60px;
}

.options_block {
    position: relative;
    z-index: 20;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    height: 30px;
}

.options_block>* {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    border: 0;
    border-radius: 0;
    text-shadow: 1px 1px 2px black;
    text-align: center;
    background-color: rgb(212, 212, 212);
}

.options_block a,
.options_block button {
    font-size: 14px;
    padding: 0;
}

.options_block>*>span {
    display: block;
    mask-repeat: no-repeat;
    mask-size: cover;
    mask-position: center;
    background-color: #FFFFFF;
    height: 23px;
    width: 23px;
    z-index: 20;
}

body .options_block>*:hover {
    transform: scale(1.3);
}

.delete,
.recycle {
    background-color: rgb(255, 46, 46);
}

.copy {
    background-color: rgb(0, 160, 147);
}

.edit {
    background-color: rgb(180, 180, 180);
}

.view {
    background-color: rgb(57, 101, 160);
}

.restore {
    background-color: rgb(34, 211, 87);
}

.recycle span {
    mask-image: var(--i_recycle);
}

.delete span {
    mask-image: var(--i_delete_doc);
}

.restore span {
    mask-image: var(--i_restore);
}

.copy span {
    mask-image: var(--i_copy);
}

.edit span {
    mask-image: var(--i_edit);
}

.view span {
    mask-image: var(--i_view);
}

/* copy form */

.form_copy {
    cursor: default;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -202px;
    height: 202px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
}

.form_copy:hover {
    transform: translateX(-50%) !important;
}

.form_copy .container {
    margin-top: 20px;
    height: 130px;
    width: 90%;
    background-color: #ffffff;
    text-align: left;
    text-shadow: none;
    font-weight: 600;
    display: grid;
    grid-template-rows: 1fr 75%;
}

.form_copy .container::after {
    content: '';
    position: absolute;
    left: 75px;
    bottom: -14px;
    border: 20px solid transparent;
    border-top: 20px solid rgb(255, 255, 255);
}

.form_copy .header {
    padding: 7px 10px;
    font-size: 16px;
    background-color: #465A65;
    color: #fff;
    border-radius: 0;
    user-select: none;
    text-align: center;
}

.form_copy .body {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    padding: 0 5px 5px 5px;
}

.form_copy .container .selector {
    width: 100%;
}

.form_copy .container .selector select {
    width: 100% !important;
    max-width: none;
    margin: 5px 0 2px 0;
}


.form_copy .send {
    width: 100%;
    margin: 5px;
    padding: 5px;
    font-size: 18px;
    background-color: rgb(0, 185, 130);
    font-weight: 600;
    color: white;
    border: none;
    cursor: pointer;
}

.form_copy .send:hover {
    background-color: rgb(0, 134, 94);
    text-decoration: underline;
}