:root {
  --i_smile: url('./assets/images/smile.svg');
  --i_city: url('./assets/images/city.png');
  --i_calendar: url('./assets/images/calendar.png');
  --i_checkmark3: url('./assets/images/check_mark3.png');
  --i_reload: url('./assets/images/reload.png');
  --i_eraser: url('./assets/images/eraser.png');
  --i_cross: url('./assets/images/cross.png');
  --i_trash: url(./assets/images/trash.svg);
  --i_copy: url(./assets/images/copy-link.png);
  --i_remove: url(./assets/images/rubbish-bin.png);
  --i_view: url(./assets/images/view.png);
  --i_delete_doc: url(./assets/images/document.png);
  --i_edit: url(./assets/images/edit.png);
  --i_recycle: url(./assets/images/rubbish-bin.png);
  --i_restore: url("./assets/images/restore.png");
  --i_checkmark: url('./assets/images/checkmark.svg');
  --i_cross: url('./assets/images/cross.svg');
  --i_history: url('./assets/images/restore.png');
  --i_pencil: url('./assets/images/pencil.png');
  --i_recovery: url('./assets/images/rcovery.png');
  --i_add: url('./assets/images/add.png');
  --i_x-mark: url('./assets/images/x-mark.png');
  --i_info: url('./assets/images/info.png');
  --i_menu_lines: url(./assets/images/menulines.png)
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6;
  font-family: Arial;
  line-height: 1;
}

html {
  height: 100%;
  padding: 0;
  margin: 0;
  min-height: 100vh;
  overflow-y: scroll !important;
  overflow-x: hidden;
}

div {
  border-radius: 3px;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
}

.linkCCAds {
  display: flex !important;
  flex-direction: row;
  width: 100% !important;
  text-decoration: none !important;
  padding: 10px 0 !important;
  border-radius: 0px !important;
  background-color: #ffffff !important;
  cursor: pointer !important;
}

.linkCCAds:hover .titleCCAds {
  text-decoration: underline;
}

.mediaContainerCCAds {
  width: 220px;
  height: 180px;
  overflow: hidden;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.mediaCCAds {
  width: 220px !important;
  height: 100% !important;
  -o-object-fit: cover !important;
  object-fit: cover !important;
  border-radius: 5px !important;
  padding: 0 !important;
  max-height: initial !important;
}

.aboutCCAds {
  display: block !important;
  width: 100% !important;
  position: relative !important;
  font-size: 26px !important;
  font-weight: 600;
  padding: 0 20px;
}

.titleCCAds {
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -moz-box;
  -moz-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  line-clamp: 4;
  box-orient: vertical;
  vertical-align: middle;
}

.buttonCCAds {
  display: block !important;
  position: absolute !important;
  right: 0;
  bottom: 0;
  color: #fff;
  padding: 12px 30px;
  font-size: 20px;
  font-weight: 500;
  -webkit-box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%);
  font-weight: 600;
  border: none;
  border-radius: 3px;
  background-color: #06b62c;
  transition: .3s;
}

.buttonCCAds:hover {
  background-color: #188f32;
  transform: scale(1.1);
}

.buttonCCAds:active {
  transform: scale(.9);
  -webkit-box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%) inset;
  box-shadow: 0 0 2px 0 rgb(0 0 0 / 12%), 0 2px 2px 0 rgb(0 0 0 / 24%) inset;
}

span.separator_page_club {
  position: relative;
  display: block;
  height: 30px !important;
  background-color: #555555;
  outline: 3px dashed black;
}

span.separator_page_club::after {
  content: 'Разрыв страницы';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 20px;
}

/* кастомизация календаря react*/

.react-calendar {
  display: flex;
  flex-flow: column nowrap;
  box-shadow: 0 3px 15px 2px rgba(56, 56, 56, 0.2);
  background-color: white;
  padding: 0 !important;
}

body .react-calendar button {
  border: none;
  background-image: none;
  box-shadow: none;
  background-color: white;
  transition: .2s;
}

body .react-calendar button:hover {
  border: none;
  background-color: #e0e0e0;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;
  background-color: rgb(233, 233, 233);
  border-radius: 0 !important;
}

.react-calendar__navigation button {
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  color: #0077ff;
}

.react-calendar__navigation>* {
  background-color: transparent !important;
}

body button.react-calendar__navigation__label {
  font-size: 20px;
}

body .react-calendar__viewContainer {
  margin: 5px 0;
  background-color: white;
}


body .react-calendar__viewContainer,
body .react-calendar__month-view,
body .react-calendar__month-view>div,
body .react-calendar__month-view>div>div {
  padding: 0 !important;
}

body .react-calendar__month-view__weekdays__weekday {
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-style: italic;
  margin-bottom: 10px;
}

body .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

body .react-calendar__month-view__days__day--weekend {
  color: red !important;
}


body .react-calendar__tile {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  padding: 3px 0 !important;
}

body .react-calendar__tile--active {
  background-color: rgb(0, 153, 255) !important;
  color: white !important;
  font-weight: 600;
}

body .react-calendar__tile--active.react-calendar__tile--now {
  background-color: rgb(0, 238, 255) !important;
}

body .react-calendar__tile--now {
  background-color: rgb(0, 194, 0) !important;
  color: white !important;
  font-weight: 600;
  border: 2px solid rgb(0, 0, 0) !important;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: gray !important;
}