.comments_wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
    height: fit-content;
    padding: 10px;
}

.comment_wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    gap: 5px;
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 0;
    overflow: hidden;
    box-shadow: 0 0 5px 1px rgba(107, 107, 107, 0.2);
    position: relative;
}

.comment_wrapper:hover {
    outline: 3px solid black;
}

.comment_wrapper a {
    color: black;
    text-decoration: underline;
    font-weight: 600;
}

.comment_area {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    gap: 8px;
}

.user_name_date {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    align-items: center;
    height: fit-content;
}

.user_name {
    font-weight: 600;
    font-size: 17px;
    background-color: #000;
    color: #fff;
    padding: 4px;
}

.date {
    color: gray;
    font-size: 14px;
    font-weight: 600;
    height: fit-content;
}

.id_comment {
    background-color: rgb(107, 107, 107);
    color: white;
    padding: 5px;
}


.like_disslike {
    display: flex;
    flex-flow: row nowrap;
    gap: 10px;
    color: white;
    text-shadow: 1px 1px 1px black;
}

.like_disslike>div {
    padding: 2px 4px;
    font-size: 12px;
}

.like_disslike>div span {
    font-weight: 600;
    font-size: 20px;
}

.likes {
    background-color: rgb(0, 185, 0);

}

.disslikes {
    background-color: #ff2828;
}

.remove {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 12px;
    height: 12px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.remove span {
    position: absolute;
    background-color: gray;
    width: 100%;
    height: 2px;
    display: block;
}

.remove span:nth-child(1) {
    transform: rotate(45deg);
}

.remove span:nth-child(2) {
    transform: rotate(-45deg);
}

.remove:hover {
    transform: scale(1.1);
}

.remove:hover span {
    background-color: black;
}

.news_id {
    position: absolute;
    right: 0;
    bottom: 0;
    width: fit-content;
    padding: 2px 5px;
    font-size: 14px;
    color: white !important;
    background-color: rgb(0, 82, 114);
    border-radius: 0;
}

.news_id span {
    font-size: 20px;
}

.comment_content {
    width: fit-content;
    padding: 0 5px 0 0;
}

.site {
    background-color: #465A65;
    color: white !important;
    text-decoration: none;
    padding: 3px 5px;
    font-size: 14px;
}