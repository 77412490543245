$main_color: rgb(0, 39, 97);

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}
@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.fullEntryBlock {
    background-image: linear-gradient(45deg, black 30%, $main_color 100%);
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .entry_block_area {
        background-color: transparent;
        max-width: 400px;
        min-height: 400px;
        margin: 0 auto;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);

        .entry_block {
            width: 100%;
            height: 350px;
            border: 3px solid $main_color;
            box-shadow: 0px 0px 18px 0.1px $main_color;

            .entry_menu {
                width: 280px;
                height: 200px;
                margin: 0 auto;
                color: white;
            }

            .title {
                float: left;
                font-size: 19px;
            }

            .controlInput {
                width: 280px;
                height: 28px;
                font-size: 18px;
                margin: 0 auto;
                margin-top: 10px;
                margin-bottom: 10px;
                outline: none;
            }

            h2 {
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 26px;
                margin: 15px 0 20px 0;
            }

            button,
            .title,
            h2 {
                -webkit-touch-callout: none;
                -webkit-user-select: none;
                -khtml-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
            }

            h2,
            .title {
                text-align: center;
                text-shadow: 3px 3px 1px $main_color;
            }

            .loginbutton {
                position: absolute;
                bottom: 80px;
                right: 40px;
                width: 100px;
                height: 35px;
                background-color: transparent;
                color: white;
                box-shadow: 0px 0px 1px 1px white;
                border-radius: 0;
                z-index: 0;
                font-size: 18px;
                cursor: pointer;
                outline: none;

                &:before {
                    content: "";
                    background: linear-gradient(
                        45deg,
                        #ff0000,
                        #ff7300,
                        #fffb00,
                        #48ff00,
                        #00ffd5,
                        #002bff,
                        #7a00ff,
                        #ff00c8,
                        #ff0000
                    );
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    background-size: 400%;
                    z-index: -1;
                    filter: blur(5px);
                    width: calc(100% + 4px);
                    height: calc(100% + 4px);
                    animation: glowing 20s linear infinite;
                    opacity: 0;
                    transition: opacity 0.3s ease-in-out;
                }

                &:active {
                    color: #000;
                }

                &:active:after {
                    background: transparent;
                }

                &:hover:before {
                    opacity: 1;
                }

                &:after {
                    z-index: -1;
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: #111;
                    left: 0;
                    top: 0;
                }
            }
            .entry_checkbox {
                margin: 0 auto;
                margin-top: 10px;
            }

            .checkbox {
                float: right;
            }

            #checkbox_title {
                margin-top: 5px;
            }

            #highload1 {
                display: none;

                & + .lb1,
                & + .lb1::before,
                & + .lb1::after {
                    transition: all 0.3s;
                }

                & + .lb1 {
                    display: inline-block;
                    position: relative;
                    top: 0px;
                    right: -50px;
                    width: 60px;
                    height: 30px;
                    border-radius: 30px;
                    cursor: pointer;
                }

                & + .lb1::before {
                    display: block;
                    content: attr(data-offlabel);
                    position: absolute;
                    top: 6px;
                    right: 5px;
                    color: rgb(255, 255, 255);
                    font-family: "Open Sans", sans-serif;
                    font-size: 14px;
                    font-weight: 700;
                }

                & + .lb1::after {
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    top: 3px;
                    left: 4px;
                    width: 24px;
                    height: 24px;
                    background-color: white;
                }

                &:checked + .lb1::before {
                    content: attr(data-onlabel);
                    left: 9px;
                    right: auto;
                    color: #fff;
                }

                &:checked + .lb1::after {
                    left: 32px;
                    background-color: #f7f7f7;
                }

                & + .lb1 {
                    background-color: rgb(77, 76, 76);
                    box-shadow: 0px 0px 10px 2px $main_color;
                }

                &:checked + .lb1 {
                    background-color: green;
                    box-shadow: 0px 0px 10px 1px rgb(255, 255, 255);
                }

                &:checked + .lb1::before {
                    color: #fff;
                }
            }

            .error_message {
                text-align: left;
                position: relative;
                width: 200px;
                font-size: 18px;
                top: -4px;
                left: 55px;
                color: red;
                display: block;
            }
        }
    }
    .loadingEl {
        opacity: 0;
        transition: cubic-bezier(0.165, 0.84, 0.44, 1);
        height: 60px;
        width: 80%;
        position: absolute;
        bottom: 20px;
        left: 100%;
        z-index: 1000;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        gap: 10px;

        &.active {
            opacity: 1;
            left: 50%;
            transform: translateX(-50%);
            .innerBrick {
                right: -100vw;
                animation: 1.5s movingX cubic-bezier(0.9, 0.3, 0.5, 0.9) infinite;
            }
        }

        .innerBrick {
            width: 100%;
            height: 33%;
            background-color: white;
            opacity: 0.9;
            border-radius: 0;
            position: relative;
            transform: translateX(100%);

            &:nth-child(1) {
                animation-delay: 0.2s;
            }
            &:nth-child(2) {
                animation-delay: 0.4s;
            }
            &:nth-child(3) {
                animation-delay: 0.6s;
            }
            .tail {
                position: relative;
                border-radius: 0;
                border: none;
                left: 100%;
                top: 0;
                bottom: 0;
                width: 400px;
                height: 100%;
                background: rgb(0, 0, 0);
                background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(0, 0, 0, 0) 100%);
                /* clip-path: polygon(0 0, 20% 0, 100% 100%, 0 100%, 0 100%); */
            }
        }
    }
}
@keyframes movingX {
    0% {
        right: 0;
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        opacity: 1;
        right: 0;

        width: 50%;
    }
    100% {
        transform: translateX(-100%);
        opacity: 0;
        right: 0;
        width: 20%;
    }
}
