@media screen and (max-width: 1160px) {
    .content_view_wrapper {
        width: 100% !important;
        height: 100% !important;
        padding: 60px 30px 30px 30px !important;
    }
}

@media screen and (max-width: 640px) {
    .content_view_wrapper {
        padding: 60px 10px 10px 10px !important;
    }
}

.main_view_wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 26;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back_view_wrapper {
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.384);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.content_view_wrapper {
    margin: 0 auto;
    width: 44%;
    height: 98%;
    min-width: 320px;
    background-color: white;
    z-index: 2;
    overflow-y: auto;
    padding: 8px;
    box-shadow: 0 0 10px black;
}

.top_content_view {
    display: flex;
    flex-direction: row;
    justify-content: right;
    padding: 8px;
    font-size: 20px;
    gap: 10px;
}

.top_content_view div:hover {
    opacity: .7;
}

.top_name_view {
    margin: 0 auto 0 0;
    font-size: 24px;
    padding-left: 5px;
}

.top_meta_view {
    padding: 3px 5px 1px 5px;
    background-color: rgb(0, 143, 209);
    color: white;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.top_cancel_view {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    padding: 3px 5px;
    background-color: rgb(209, 0, 0);
    color: white;
}

.top_cancel_view::after {
    content: '';
    position: absolute;
    width: 70%;
    height: 70%;
    background-color: rgb(255, 255, 255);
    mask-image: var(--i_cross);
    mask-position: center;
    mask-size: cover;
    mask-repeat: no-repeat;
}

.center_content_view {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.center_title_view {
    padding: 10px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 24px;
    font-weight: 600;
}

.center_about_view {
    display: flex;
    flex-flow: row wrap;

    font-weight: 600;
    padding: 5px 10px;
    font-size: 15px;
    color: black;
    gap: 5px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.center_about_view .about_title {
    color: rgb(134, 134, 134);
    font-weight: 300;
}

.center_media_view {
    padding: 0 10px;
    width: 100%;
    max-width: 100%;
    margin: 0;
    min-height: 150px;
    height: auto;
    overflow: hidden;
}

.slide_media_container {
    width: 100%;
    max-height: 480px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media {
    object-fit: cover;
    width: auto;
    max-width: 100%;
    height: 100%;
}

.bottom_content_view {
    word-break: break-word;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    hyphens: auto;

    margin-top: 10px;
    max-width: 100%;
    position: relative;
    padding: 0 10px 50px 10px;
}

.bottom_content_view iframe {
    width: 100% !important;
    height: 100% !important;
    max-width: 100% !important;
    min-height: 360px !important;
}

.bottom_content_view p:has(img) {}

.bottom_content_view img[style*='float: left'] {
    margin-right: 10px !important;
}

.bottom_content_view img[style*='float: right'] {
    margin-left: 10px !important;
}

.bottom_content_view img, .description video {
    padding: 15px 0;
    object-fit: cover;
    margin: 0 auto;
}

.bottom_content_view div {
    clear: both;
}

.bottom_content_view ul {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
}

.bottom_content_view li {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.bottom_content_view li::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000000;
    margin-right: 5px;
}

.bottom_content_view figure {
    margin: 0;
}

.bottom_content_view blockquote,
.bottom_content_view hr,
.bottom_content_view p,
.bottom_content_view h2,
.bottom_content_view h1,
.bottom_content_view h3,
.bottom_content_view ol,
.bottom_content_view dl {
    margin-top: 3px;
    margin-bottom: 3px;
    max-width: 100%;
}

.bottom_content_view span {
    display: inline-block;
}

.bottom_content_view span, .bottom_content_view img {
    max-width: 100%;
    height: auto;
}

.hr {
    background-color: #000000;
    border: none;
    height: 3px;
    width: 100%;
    background-image: linear-gradient(to left, #000000, #FFF);
    opacity: .3;
}

#hr_top {
    margin: 15px 10px;
    background-image: linear-gradient(to right, #000000, #FFF);
}

#hr_bottom {
    margin: 15px 10px 5px 10px;
    background-image: linear-gradient(to right, #000000, #FFF);
}

.loading_record {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0);
}

.loading_record:after {
    animation: changeContent .8s linear infinite;
    display: block;
    content: "⠋";
    font-size: 80px;
}

@keyframes changeContent {
    10% {
        content: "⠙";
    }

    20% {
        content: "⠹";
    }

    30% {
        content: "⠸";
    }

    40% {
        content: "⠼";
    }

    50% {
        content: "⠴";
    }

    60% {
        content: "⠦";
    }

    70% {
        content: "⠧";
    }

    80% {
        content: "⠇";
    }

    90% {
        content: "⠏";
    }
}

.about_viewer {
    font-size: 12px;
    font-style: italic;
    color: rgb(163, 163, 163);
    margin: 0 auto;
    width: fit-content;
}