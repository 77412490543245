@media screen and (max-width: 640px) {
    .search_input {
        width: 100% !important;
    }
}

.search_button,
.search_input {
    padding: 0;
    border: 0;
}

.searcher_area {
    display: inline-flex;
    height: 40px;
    min-height: 40px;
    width: auto;
    border-radius: 10px !important;
    overflow: hidden;
    margin: 10px 5px 15px 5px;
    border-radius: 0 15px 15px 0 !important;
    overflow: hidden;
    outline: 2px solid #535c69;
}

.searcher_parameter {
    float: left;
}

.search_input {
    width: 280px;
    height: 100%;
    padding-left: 10px;
    outline: none;
    background: #ffffff;
    color: #9E9C9C;
    border-radius: 10px 0 0 10px;
    font-size: 18px;
}

.search_button {
    background-image: url(../assets/images/loupe.png);
    background-size: 22px;
    background-position: center;
    background-repeat: no-repeat;

    width: 42px;
    height: 100%;
    background-color: #535c69;
    cursor: pointer;
}

.search_selector {
    margin-right: 5px;
    height: 33px;
    position: relative;
    top: 1px;
    padding-left: 5px;
    font-size: 16px;
    min-width: 120px;
    max-width: 300px;
    word-break: break-all;
    overflow: hidden;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
    outline: none;
}

.search_selector::-ms-expand {
    display: none;
}

.search_selector:hover {
    border-color: #888;
}

.search_selector:focus {
    color: rgb(31, 44, 65);
}

.search_selector option {
    font-weight: normal;
    font-size: 18px;

}

*[dir="rtl"] .search_selector,
:root:lang(ar) .search_selector,
:root:lang(iw) .search_selector {
    background-position: left .7em top 50%, 0 0;
    padding: .6em .8em .5em 1.4em;
}