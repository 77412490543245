:root {
  --width_btns: 100%;
}

/* подпись к тестовому полю */
.insc {
  margin-bottom: 8px;
  color: black;
  padding: 1px 0 1px 0;
  font-size: 19px;
  display: flex;
  flex-flow: row wrap;
  gap: 5px;
  position: relative;
}

.error_msg {
  position: relative;
  right: 0;
  font-size: 14px;
  color: red;
}

/* счётчик символов */

.max_lenght {
  float: right;
  font-size: 14px;
  font-weight: 100;
  font-family: Helvetica, sans-serif;
  position: relative;
  bottom: 8px;
  color: #908080;
}

.err_max_chars {
  color: red;
  font-size: 20px;
}

/* контейнер для ссылок на медиа для их загрузки */

.links_media_input_container {
  width: 100%;
  margin: 10px 0;
}

.lmic_input_container {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  align-items: center;
}

.lmic_input_container .input_container {
  width: 100%;
}

.lmic_input_container .fortext {
  margin-bottom: 10px;
}

.lmic_input_container .lmic_button {
  margin: 0 auto 0 0;
  background-color: #2AAC51;
  border-radius: 5px;
  color: white;
  outline: none;
  border: 0;
  font-size: 18px;
  height: fit-content;
  min-width: 14%;
  cursor: pointer;
}

.lmic_input_container .lmic_button:not(.noValid):hover {
  background: rgba(17, 209, 120, 1);
  background: linear-gradient(360deg, rgb(0, 158, 84) 0%, rgb(0, 228, 121) 60%);
  transform: scale(1.05);
}

.lmic_input_container .noValid {
  user-select: none;
  background: rgb(218, 218, 218);
  cursor: default;
}


/* стандартное текстовое поле, 1 строка */
.input_container {
  position: relative;
}

.input_container .insc {
  display: flex;
  align-items: center;
  margin-bottom: 2px;
}

.input_container .buttons_wrapper {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  gap: 5px;
}

.fortext {
  border: 2px solid #eee;
  border-bottom: 2px solid #9e9e9e;
  border-radius: 5px;
  height: auto;
  margin: 3px 0 20px 0;
  width: 100%;
  font-size: 18px;
  padding: 3px 5px;
  background-color: transparent;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input.fortext {
  height: 35px !important;
}

.input_container .wrapper_target_link {
  border: 1px solid gray;
  background-color: rgb(248, 248, 248);
  background-image: linear-gradient(to top, rgb(250, 250, 250), white);
  width: 33px;
  height: 33px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.input_container .emojiPicker {
  position: absolute;
  top: -15px;
  left: 115px;
  z-index: 150;
}

.input_container .emojiPicker aside {
  background-color: rgba(255, 255, 255, 0.9);
}

.input_container .wrapper_target_link:hover {
  background-image: linear-gradient(to top, rgb(197, 197, 197), white);
  border: 1px solid rgb(0, 0, 0);
}

.input_container .target_link_city,
.input_container .target_link_emoji,
.input_container .target_link_date {
  width: 25px;
  height: 25px;
  display: block;
  background-color: #000;
  mask-size: contain;
  mask-position: center;
  mask-repeat: no-repeat;
}

.input_container .target_link_emoji {
  mask-image: var(--i_smile);
}

.input_container .target_link_city {
  mask-image: var(--i_city);
}

.input_container .target_link_date {
  mask-image: var(--i_calendar);
}

.input_container .max_lenght {
  position: absolute;
  right: 0;
  bottom: 2px;
}


/* multiple select-option */
.multiple_selector_block {
  padding: 0 0 20px 0;
}

.multiple_selector_block .insc {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
}

.multiple_selector_container {
  width: 100%;
  position: relative;
}

.multiple_selector_map {
  position: relative;
  z-index: 99;
  background-color: rgb(255, 255, 255);
  width: fit-content;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 10px;
  user-select: none;
  cursor: pointer;
  font-weight: 600;
  border-radius: 0;
}


.expand {
  display: block;
  width: 10px;
  height: 10px;
  border: 5px solid transparent;
  border-top: 5px solid black;
}

.multiple_selector_map .selected_option {
  border: 1px solid black;
  padding: 10px;
}

.multiple_selector_map .selected_option:first-child {
  background-color: lightgray;
  background-image: linear-gradient(to top, rgb(231, 231, 231), white);
  border-radius: 0;
}

.multiple_selector {
  position: absolute;
  left: 0;
  top: 48px;
  width: max-content;
  max-width: 300px;
  background-color: white;
  z-index: 100;
  border: 1px solid black;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.2);
  display: none;
}

.multiple_selector.active {
  display: block;
}

.multiple_selector_custom_option {
  padding: 5px 5px 5px 10px;
  border-radius: 0;
  user-select: none;
  min-height: 35px;
  display: grid;
  grid-template-columns: auto 30px;
}


body .multiple_selector_custom_option:hover {
  background-color: #1E90FF;
  color: white;
}

.multiple_selector_custom_option:hover .checkmark {
  background-color: #fff;
}

.multiple_selector_custom_option:nth-child(2n+2) {
  background-color: rgb(241, 241, 241);
}

.checkmark {
  margin-left: 5px;
  flex: 0 0 25px;
  display: block;
  width: 25px;
  height: 25px;
  background-color: #000;
  mask-image: var(--i_checkmark3);
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
}

.note {
  display: block;
  width: 100%;
  border-radius: 0;
  border: none;
  outline: none;
  background-color: #f1f1f1;
  padding: 4px;
  font-weight: 600;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  resize: none;
}

/* editor tinymce */

.create_menu_textarea .max_lenght {
  top: 0px;
}

.create_menu_textarea {
  position: relative;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.editor_container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.editor_container html {
  width: 100% !important;
  max-width: 100% !important;
  overflow: hidden !important;
}

.state_radio {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: fit-content;
}

/* tripple radio */
.form_toggle {
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  overflow: hidden;
  width: auto;
}

.form_toggle_item {
  display: flex;
  flex-flow: row nowrap;
}

.form_toggle_item input[type=radio] {
  display: none;
}

.form_toggle_item label {
  display: inline-block;
  padding: 0px 15px;
  line-height: 34px;
  border: 1px solid #999;
  border-right: none;
  cursor: pointer;
  user-select: none;
  transition: .1s;
  border-radius: 0;
}

.form_toggle_item label:hover {
  background-color: rgb(243, 243, 243);
}

.form_toggle .form_toggle_item:first-child label {
  border-radius: 6px 0 0 6px;
  border-left: 1px solid #999;
}

.form_toggle .form_toggle_item:last-child label {
  border-radius: 0 6px 6px 0;
  border-right: 1px solid #999;
}

/* Checked */
.form_toggle .form_toggle_item input[type=radio]:checked+label {
  background: #bbffbb;
}

.form_toggle .form_toggle_item:hover input[type=radio]:checked+label {
  background: #3eff3e;
}

/* drag'n'drop */

.upload_img {
  width: 120px;
  height: 50%;
  margin-bottom: 20px;
  user-select: none;
}

.fileArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 100%;
}

.fileMedia_wrapper {
  width: fit-content;
  max-width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.fileMedia {
  object-fit: cover !important;
  width: auto;
  height: 100%;
  max-height: 360px;
  background-color: rgb(202, 202, 202);
  animation: glass 1.2s linear infinite;
}

@keyframes glass {
  0%, 100% {
    background-color: rgb(202, 202, 202);
  }

  50% {
    background-color: rgb(235, 235, 235);
  }
}

.cancel_btn {
  float: right;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
  z-index: 12;
  padding-bottom: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(219, 0, 0);
  border: 1px solid rgb(255, 255, 255);
  font-size: 22px;
  color: white;
}

.cancel_btn:hover {
  background-color: rgb(168, 0, 0);
}



.upload_container {
  position: relative;
}

.rejectStyle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgb(219, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  color: white;
  font-size: 23px;
}

/* textarea: autoresize */


.ap_bc_block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ap_bc_block .ap_bc_title {
  font-size: 22px;
}

.ap_bc_group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 100%;
}

.ap_bc_group div.checkbox>div {
  font-size: 22px;
  color: gray;
}

.ap_bc_group div.checkbox {
  max-width: 200px;
  display: flex;
  align-items: center;
  text-align: center;
}

.ap_bc_group div.checkbox label {
  box-shadow: none !important;
}

.ap_bc_group div.checkbox label::before {
  color: white !important;
}

.ap_bc_input {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
  color: black;
}

textarea.ap_bc_input {
  resize: none;
  overflow-y: hidden;
  height: 41px;
}

.ap_bc_input::placeholder {
  color: transparent;
}

.ap_bc_input:placeholder-shown~.ap_bc_label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}


.ap_bc_label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #7c7c7c;
  user-select: none;
}

.ap_bc_input:focus {
  padding-bottom: 6px;
  border-width: 3px;
  border-image: linear-gradient(to right, #11998e, #38ef7d);
  border-image-slice: 1;
}


.ap_bc_input:focus~.ap_bc_label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #11998e;
  font-weight: 700;
}


.ap_bc_input:required, .ap_bc_input:invalid {
  box-shadow: none;
}

.ap_bc_maxlength {
  margin: 0 0 0 auto;
}