:root {
    --media_width: 230px;
    --media_height: 360px;
    --maxheight_title: 58px;
    --maxwidth_title: 100%;
    --width_identificator: 70px;
    --width_meta: 200px;
    --height_keywords: 24px;
    --margin_keywords: 6px;
    --bottom_meta: var(--height_keywords);
    --height_descLink: calc(var(--media_height) - var(--maxheight_title) - var(--height_keywords) - 12px);
    --height_section_container: calc(var(--height_descLink) - 27px - 25px - 28px - 31px - 1px);
    --height_inner_section_container: 54px;
    --width_newViewBlock: 72.5%;
    --social_icons: url('../assets/images/social_icons.png')
}

@media screen and (max-width:640px) {
    .newViewBlock {
        padding: 10px !important;
    }

    .mediaContainer {
        height: fit-content !important;
    }
}

.newViewBlock::-webkit-scrollbar, .content_item::-webkit-scrollbar, body::-webkit-scrollbar {
    width: 12px;
}

.newViewBlock::-webkit-scrollbar-track, .content_item::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

.newViewBlock::-webkit-scrollbar-thumb, .content_item::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
    background-color: rgb(146, 146, 146);
    border: 3px solid rgb(255, 255, 255);
    border-right: none;
    border-left: none;
}


.tableWithItems {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(min(100%/1, max(250px, 100%/4)), 1fr));

    grid-column-gap: 40px;
    grid-row-gap: 20px;
    grid-column-end: span;
    grid-auto-flow: dense;
}

.tableWithItems:nth-child(31n + 1) {
    grid-column: 1 / -1.5;
}

.tableWithItems:nth-child(16n + 2) {
    grid-column: -3 / -3;
}

.tableWithItems:nth-child(16n + 10) {
    grid-column: 1 / -3.5;
}

.itemInTable {
    border-radius: 2%;
    box-shadow: 0 2px 10px #b9b9b9;
    display: block;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    transition: all .1s;
    width: 100%;
}

.itemInTable:hover {
    box-shadow: 0 0 20px #616161;
}

.topBlock_itemInTable {
    width: 100%;
    position: relative;
}

.mediaContainer_itemInTable {
    width: 100%;
    height: 180px;
}

.imageItem_forView {
    border-radius: 2% 2% 0 0;
    content-visibility: auto;
    object-fit: cover;
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: block;
}

.imageItem_forView:has(.view_image) {
    object-fit: contain;
}

.bottomBlock_itemInTable {
    padding: 0 10px 10px;
}

.about_itemInTable {
    color: #777;
    font-family: Gill Sans, Gill Sans MT, Calibri, Trebuchet MS, sans-serif;
    padding-bottom: 5px;
    font-size: 16px;
    letter-spacing: -.5px;
    margin: 0;
    padding-left: 20px;
}

.about_itemInTable li span {
    font-weight: 600;
}

.title_itemInTable {
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    white-space: pre-wrap;
    width: 100%;
    word-break: break-word;
    text-align: left;
}

.description_itemInTable {
    -webkit-line-clamp: 3;
    line-clamp: 3;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
    font-size: 15px;
    margin: 3px 0;
    text-align: left;
    word-wrap: break-word;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    height: fit-content;
}

.metaposition {
    position: absolute;
    top: 4px;
    right: 4px;
    display: flex;
    flex-flow: column wrap;
    gap: 3px;
}

.partition_itemInTable {
    position: relative;
}

.partition_itemInTable .insc_position {
    background-color: #DD7924;
    color: white;
    box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.3);
    padding: 3px 6px;
    border-radius: 0;
    cursor: pointer;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 5px;
}

.partition_itemInTable .insc_position:hover {
    background-color: #ff7700;
}

.partition_itemInTable:hover .positions {
    z-index: 10;
    opacity: 1;
}

.partition_itemInTable .positions {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: -1;
    transition: ease-in-out .2s;
    opacity: 0;
    display: flex;
    flex-flow: column nowrap;
    gap: 3px;
    background-color: #fff;
    min-width: max-content;
    width: 100%;
    padding: 3px;
    border-radius: 0;
}

.partition_itemInTable .positions div {
    background-color: #f19340;
    padding: 3px 5px;
    color: white;
    border-radius: 0;
}

.partition_itemInTable[data-active='0'] {
    background-color: rgb(0, 0, 0);
    border: 3px solid red
}

.metaposition .insc_ads {
    background-color: #000000;
    margin: 0 0 0 auto;
    color: #fff;
    width: fit-content;
    font-size: 14px;
    padding: 3px 5px;
}

.listMeta_itemInTable {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    background-color: white;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    overflow-y: auto;
}

.title_listMeta {
    font-weight: 600;
    padding: 5px 0;
    font-size: 20px;
}

.keyWords_itemInTable {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.keyWord {
    background-color: rgb(0, 132, 255);
    color: white;
    padding: 3px 5px;
    margin-right: 4px;
    margin-bottom: 5px;
}

.descMeta_itemInTable {}

.id_targetPost_itemInTable {
    position: absolute;
    left: -15px;
    top: -15px;
    z-index: 22;
    background-color: rgba(255, 0, 0, 0.623);
    color: white;
    padding: 5px;
    border-radius: 2px;
    font-weight: 600;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
}

.siteName {
    position: relative;
    z-index: 20;
    background-color: rgb(0, 136, 226);
    color: white;
    padding: 2px 5px;
    border-radius: 0;
    display: flex;
    gap: 5px;
    cursor: pointer;
}

.itemInTable[data-table="adv"] .siteName {
    cursor: default;
}

.itemInTable[data-table="news"] .siteName::after {
    content: 'перейти';
    opacity: 0;
    transition: .5s;
}

.siteName:hover {
    background-image: linear-gradient(-45deg, rgb(115, 171, 255) 0%, rgb(0, 136, 226) 100%);
}

.itemInTable[data-table="news"] .siteName:hover::after {
    opacity: 1;
}


.itemButtons {
    position: absolute;
    bottom: 20px;
    right: 0;
    display: flex;
    flex-flow: row-reverse wrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    z-index: 19;
}

.copy_link,
.filter,
.sharebutton,
.goToLink_itemInTable,
.metacontent {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    align-items: center;
    color: white;
    transition: .3s;
    box-shadow: -2px -1px 5px rgba(0, 0, 0, 0.479);
    border-radius: 0;
    cursor: pointer;
}

.filter {
    z-index: 14;
}

.copy_link {
    z-index: 13;
    background-image: url(../assets/images/copy.png);
}

.goToLink_itemInTable {
    z-index: 12;
    background-image: url(../assets/images/globe.png);
}

.sharebutton {
    z-index: 11;
    background-image: url(../assets/images/share.png);
}

.metacontent {
    z-index: 10;
    background-image: url(../assets/images/info.png);
}


.filter[data-checked='0'] {
    background-color: lightgreen;
    background-image: url(../assets/images/play-button.png);
}

.filter[data-checked='1'] {
    background-color: rgb(255, 104, 104);
    background-image: url(../assets/images/pause.png);
}

.copy_link:hover,
.sharebutton:hover,
.goToLink_itemInTable:hover,
.metacontent:hover {
    background-color: rgb(204, 204, 204);
}

.filter[data-checked='0']:hover {
    background-color: rgb(32, 240, 32);
}

.filter[data-checked='1']:hover {
    background-color: rgb(243, 48, 48);
    transform: rotate(90deg);
}

.oncopy {
    position: relative;
    background-color: rgb(74, 255, 74) !important;
}

.oncopy::after {
    content: 'Скопировано в буфер обмена';
    position: absolute;
    left: 50%;
    top: -100%;
    width: 160px;
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    background-color: #fff;
    color: #000000;
    border-radius: 25px;
    transform: translate(-50%, 10%);
    box-shadow: 0 0 14px 1px rgba(0, 0, 0, 0.3);
}

.oncopy::before {
    content: '';
    background-color: white;
    width: 15px;
    height: 15px;
    display: block;
    position: absolute;
    left: 50%;
    top: -50%;
    transform: translate(-50%, 0%) rotate(45deg);
}

.share_wrapper {
    position: absolute;
    z-index: 900;
    right: 50%;
    transform: translateX(50%);
    bottom: 60px;
    width: fit-content;
    height: fit-content;
    border-radius: 10px;
    background-color: #ffffff;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.479);
    padding: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 5px;
}

.share_wrapper::before {
    content: '';
    position: absolute;
    z-index: -1;
    left: 50%;
    bottom: -20px;
    width: 25px;
    height: 25px;
    background-color: white;
    transform: rotate(45deg) translateX(-50%);
}

.share_icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 2;
    transition: all .4s cubic-bezier(.68, -.55, .265, 1.55);
}

.share_button {
    position: relative;
    z-index: 2;
    width: 42px;
    height: 42px;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    background: var(--social_icons) no-repeat scroll transparent;
    border-radius: 5px;
    overflow: hidden;
}

.share_button:hover {
    transform: scale(1.1);
}

.vk {
    background-position: -167px 0;
}

.ok {
    background-position: -125px 0;
}


.tg {
    background-position: right 0;
}

.wh {
    background-position: -82px 0;
}

.pin {
    background-position: -209px 0;
}


.tw {
    background-position: -41px 0;
}

.meta_wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11;
    background-color: white;
    padding: 15px 5px 40px 5px;
    height: 100%;
    width: 100%;
    overflow-y: auto;
}

.meta_wrapper h3 {
    font-size: 18px;
    margin: 5px 0;
}

.meta_wrapper div {
    padding: 5px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 16px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.meta_wrapper .keyword {
    background-color: #0077FF;
    color: white;
    padding: 3px 5px;

}

.views_itemInTable,
.averageRating {
    color: black;
}



.view_container {
    width: 100%;
    position: relative;
    bottom: var(--bottom_meta);
}

.view_text {
    padding: 2px;
    border-radius: 0;
    background-color: #399c6b;
    color: white;
    font-size: 18px;
    text-align: center;
}

.views_item {
    padding: 2px;
    border-radius: 0;
    background-color: #62ce98;
    color: white;
    font-size: 20px;
    text-align: center;
}

.partition_container {
    width: 100%;
    margin-top: 44px;
    position: relative;
    bottom: var(--bottom_meta);
}

.partition_text {
    padding: 2px;
    border-radius: 0;
    background-color: #000000b9;
    color: white;
    font-size: 20px;
    text-align: center;
}

.partition_item {
    padding: 2px;
    border-radius: 0;
    background-color: #0000003f;
    color: white;
    font-size: 20px;
    text-align: center;
}


.metadata_container {
    width: 100%;
}

.checkBox_text {
    background-color: #fefefe;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding-left: 20px;
    font-size: 20px;
    font-family: sans-serif;
    -webkit-box-shadow: 0px -2px 2px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px -2px 2px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 0px -2px 2px 0px rgba(34, 60, 80, 0.2);
    border-radius: 0;
}


.newViewBlock {
    overflow-y: auto;
    width: 41.25%;
    height: 95%;
    left: 50%;
    transform: translateX(-50%);
    top: 20px;
    position: relative;
    z-index: 10;
    background-color: white;
    padding: 10px 27px 10px 27px;
    border: 1px solid black;
    border-radius: 0;
}

.titleElem {
    width: 100%;
    height: auto;
    padding: 20px 10px 0 10px;
    margin-top: 0;
    color: black;
    white-space: pre-wrap;
    word-break: break-word;
    font-size: 24px;
    font-weight: bold;
    background-color: rgba(0, 0, 0, 0.027);
}

.mediaContainer {
    position: relative;
    width: 100%;
    height: auto;
    max-height: 600px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mediaContainer .imageItem_forView {
    object-fit: contain;
}

.slider {
    position: absolute;
    top: 90%;
    left: 50%;
    transform: translate(-50%, -90%);
    -ms-transform: translate(-50%, -90%);
}

.right_btn_slider {
    transform: rotate(180deg);
}

.left_btn_slider {
    margin-right: 30px;
}

.right_btn_slider, .left_btn_slider {
    opacity: .7;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 4px 9px 2px transparent;
    -moz-box-shadow: 0px 4px 9px 2px transparent;
    box-shadow: 0px 4px 9px 2px transparent;
    border-radius: 50%;
    background-image: url(../assets/images/arrow.png);
    background-repeat: no-repeat;
    border: 4px solid transparent;
    background-size: cover;
}

.right_btn_slider:hover, .left_btn_slider:hover {
    background-color: rgb(187, 187, 187);
}

.bodyContainer {
    word-wrap: break-word;
    font-size: medium;
    margin-top: 40px;
    max-width: 100%;
    position: relative;
    white-space: pre-wrap;
    word-break: break-word;
    overflow: hidden;
}

.bodyContainer iframe {
    width: 100% !important;
}

.bodyContainer img[style*='float: left'] {
    margin-right: 10px !important;
}

.bodyContainer img[style*='float: right'] {
    margin-left: 10px !important;
}

.bodyContainer div {
    clear: both;
}

.bodyContainer ul {
    display: flex;
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 0;
}

.bodyContainer li {
    display: flex;
    flex-direction: row;
    list-style: none;
    align-items: center;
}

.bodyContainer li::before {
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: #000000;
    margin-right: 5px;
}

.bodyContainer figure {
    margin: 0;
}

.bodyContainer blockquote,
.bodyContainer hr,
.bodyContainer p,
.bodyContainer h2,
.bodyContainer h1,
.bodyContainer h3,
.bodyContainer ol,
.bodyContainer dl {
    margin-top: 5px;
    margin-bottom: 5px;
    max-width: 100%;
}

.bodyContainer span, .bodyContainer img {
    max-width: 100%;
    height: auto;
}

.info_of_item {
    height: 20px;
}

.views_elem {
    float: right;
    font-size: 20px;
}

.datePartElem {
    font-size: 18px;
    float: left;
}

.type_adv {
    position: absolute;
    bottom: 160px;
    color: white;
    padding: 2px 5px 2px 5px;
    text-shadow: 1px .5px 2px rgb(0, 0, 0);
    background-color: rgb(174, 0, 255);
}


.frameVideoPlayer {
    background-image: url(../assets/images/pleer.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 11;
    opacity: .8;
}

.playButtonFrameVideoPlayer {
    background-image: url(../assets/images/play.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 50px;
    width: 50px;
    opacity: .8;
    position: absolute;
    left: 50%;
    bottom: 50%;
    z-index: 11;
    transition: .3s;
    transform: translate(-50%, 50%);
}

.playButtonFrameVideoPlayer:hover {
    transform: translate(-50%, 50%) scale(1.1);
}

.playButtonFrameVideoPlayer:hover:active {
    transform: translate(-50%, 50%) scale(.9);
}

.expand {
    display: block;
    width: 10px;
    height: 10px;
    margin: 5px 0 0 0;
    border: 5px solid transparent;
    border-top: 5px solid black
}