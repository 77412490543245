@media screen and (max-width:640px) {
    #up, #down {
        right: 15px !important;
    }

    #up {
        bottom: 90px !important;
    }

    #down {
        bottom: 30px !important;
    }
}

.pagesWithButtons {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 22;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

button {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.pagesWithButtons, .btn_Pagination, .ul_pagination, .page_item {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    font-size: 22px;
}

.btn_Pagination {
    border: 0;
    padding: 8px 10px;
    font-weight: 600;
}

.btn_Pagination:first-child {
    position: relative;
}

.ul_pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0;
}

.page_item {
    padding: 8px;
    width: 40px;
    list-style-type: none;
}

.btn_Pagination_span {}

.btn_Pagination, .page_item {
    cursor: pointer;
    color: white;
    background-color: #465A65;
}

.btn_Pagination:hover, .page_item:hover {
    background-color: #000000;
}

.btn_Pagination[aria-disabled='true'],
.btn_Pagination[aria-disabled='true']:hover,
.page_item_active,
.page_item_active:hover {
    cursor: default;
    background-color: #000000ce;
    transform: scale(.7);
}

.loading {
    font-size: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

#up {
    border: 0;
    cursor: pointer;
    position: fixed;
    bottom: 70px;
    right: 170px;
    text-align: center;
    width: 45px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    border-radius: 50%;
}

#up:hover {
    background-color: rgb(221, 221, 221);
}

#up::after {
    content: '';
    position: absolute;
    top: 10px;
    right: 12px;
    width: 15px;
    height: 15px;
    border-top: 6px solid #575757;
    border-right: 6px solid #838383;
    transform: rotate(-45deg);
}

#up::before {
    content: '';
    position: absolute;
    top: 20px;
    right: 12px;
    width: 15px;
    height: 15px;
    border-top: 6px solid #575757;
    border-right: 6px solid #838383;
    transform: rotate(-45deg);
}

#down {
    border: 0;
    cursor: pointer;
    position: fixed;
    bottom: 70px;
    right: 100px;
    text-align: center;
    width: 45px;
    height: 45px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    border-radius: 50%;
}

#down:hover {
    background-color: rgb(221, 221, 221);
}

#down::after {
    content: '';
    position: absolute;
    top: 3px;
    right: 12px;
    width: 15px;
    height: 15px;
    border-top: 6px solid #838383;
    border-right: 6px solid #575757;
    transform: rotate(135deg);
}

#down::before {
    content: '';
    position: absolute;
    top: 13px;
    right: 12px;
    width: 15px;
    height: 15px;
    border-top: 6px solid #838383;
    border-right: 6px solid #575757;
    transform: rotate(135deg);
}