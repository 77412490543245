$arrow_image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");

.select_container {
    padding: 0 !important;

    .head {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        font-size: 18px;
        margin: 0 0 12px 0;
    }
}

.filter_container {
    position: relative;
    border-radius: 0;

    .selector {
        z-index: 20;
        margin: 0;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-flow: column nowrap;
        height: 100%;
        border-radius: 0;

        .option {
            padding: 0;
            font-weight: 600;
            padding-right: 20px;
            display: block;
            white-space: nowrap;

            .title_option {
                padding: 0;
            }
        }
    }

    .options_list {
        display: flex;
        flex-flow: column nowrap;
        position: absolute;
        left: 0;
        top: 100%;
        background-color: #fff;
        border-radius: 0;
        z-index: 100;
        padding: 5px 0 0 0;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.1);
        min-width: 100%;
        max-height: 520px;
        overflow-y: auto;
        overflow-x: hidden;

        .option {
            user-select: none;
            width: 100%;
            height: 100%;
            padding: 3px 6px;
            border-radius: 0;
            position: relative;
            white-space: nowrap;
            cursor: pointer;

            .title_option {
                padding: 0;
            }

            &::after {
                content: "";
                display: block;
                width: 100%;
                height: 1px;
                background-color: #0000003d;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 20;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
            &:hover {
                background-color: #4084d1;

                .title_option {
                    color: white;
                }
            }
        }
    }
    //вложенный селектор
    .filter_container {
        display: flex;
        flex-flow: column nowrap;
        height: 100%;
        background-color: #eeeeee;
        padding: 0;

        .selector {
            background-color: transparent;
            background-image: none;
            border: none;
            width: 100%;
            box-shadow: none;
            padding: 0 20px 0 0;
            display: flex;

            .option {
                position: relative;
                background-color: transparent;
                height: 100%;
                width: 100%;

                .title_option {
                    padding: 0;
                }
                .arrow {
                    position: absolute;
                    right: -15px;
                    top: 0;

                    height: 100%;
                    width: 20px;

                    &:hover {
                        color: black;
                        transform: scale(1.1);
                        background: #fff;
                        outline: 3px solid black;
                    }
                }
            }
            &:hover {
                background-color: #4084d1;

                .title_option {
                    color: white;
                }
                .arrow {
                    color: rgb(255, 255, 255);
                }
            }
        }
        .options_list {
            background-color: transparent;
            position: relative;
            left: unset;
            top: unset;
            box-shadow: none;
        }
    }
}

.selector {
    display: block;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    width: auto;
    min-width: 150px;
    box-sizing: border-box;
    margin: 10px 0 20px 0;
    border: 1px solid #aaa;
    appearance: none;
    background-color: #fff;
    background-image: $arrow_image, linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right 5px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    padding: 5px 18px 5px 5px;
    color: rgb(0, 0, 0);
    transition: 0.2s;

    &:hover {
        border-color: #000000;
    }
    &:focus {
        box-shadow: 0 0 7px 1px rgba(0, 0, 0, 0.2);
        outline: none;
    }
    &::-ms-expand {
        display: none;
    }
}

.option,
option {
    font-weight: normal;
    color: #222;
    font-size: 18px;
}

.current_filter {
    .selector {
        background-color: #64adff !important;
        border-left: 5px solid black !important;

        .arrow,
        .title_option {
            color: white;
        }
    }
}

.current {
    background-color: #64adff !important;
    border-left: 5px solid black;
    .arrow,
    .title_option {
        color: rgb(255, 255, 255);
    }
}
