:root {
    --width_content: 65%;
    --height_content: 100%;
    --images_size: 170px;
    --images_box: 170px;
    --reload: url(../assets/images/reloading.png);
}

button {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 1500px) {
    .mainWrapper {
        padding-top: 65px !important;
    }
}

@media screen and (max-width: 1100px) {
    .mainWrapper {
        padding: 65px 10px 20px 10px !important;
    }

    .leftColumnWrapper div {
        width: 100% !important;
        margin: 0 !important;
    }

    .centralColumnWrapper {
        grid-template-columns: 1fr !important;
    }

    .rightColumnWrapper .widgetsArea {
        width: 100% !important;
        writing-mode: vertical-rl;
    }

    .rightItemsBlock {
        display: none !important;
    }
}

@media screen and (max-width: 640px) {
    .mainWrapper {
        grid-template-columns: 1fr !important;
    }

    .leftColumnWrapper, .rightColumnWrapper, .keywordsBlock, .shareBlock, .commentsBlock, .bottomAds, .topAds {
        display: none !important;
    }
}

.mainWrapper {
    display: grid;
    grid-template-columns: 1fr 55.5% 1fr;
    padding: 20px 0;
}

.leftColumnWrapper, .rightColumnWrapper {
    height: 100%;
    width: 100%;
    padding: 10px;
}

.leftColumnWrapper div {
    writing-mode: vertical-rl;
    margin: 0 20px 0 auto;
    width: 40%;
    background-color: white;
    opacity: .4;
    border: 3px dashed black;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    padding: 10px;
}


.widgetsArea {
    height: 290px;
    width: 250px;
    margin: 0 auto;
    position: sticky;
    top: 120px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 24px;
    border: 3px dashed black;
    opacity: .4;
    white-space: pre-wrap;
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
    padding: 10px;
}

.centralColumn {
    background-color: white;
    min-height: 250px;
    max-width: 100%;
    box-shadow: 0px 1px 10px rgb(175, 175, 175);
    margin-bottom: 60px;
}

.centralColumn:has(.oneItemPageBlock) {
    max-width: 80%;
}

.paths_page {
    position: relative;
}

/* localStorage button */

.get_item_localstorage_btn {
    position: absolute;
    right: 15px;
    top: 15px;
    width: 35px;
    height: 35px;
    border: none;
    background-color: rgb(0, 0, 0);
    mask-image: var(--i_restore);
    mask-position: center;
    mask-repeat: no-repeat;
    mask-size: cover;
    cursor: pointer;
    transition: .5s;
}

.get_item_localstorage_btn:hover {
    transform: rotate(-360deg);
    background-color: gray;
}

.get_item_localstorage_btn:active {
    transition: 0.1s;
    transform: scale(1.2) rotate(-170deg);
    background-color: black;
}

.oneItemPageBlock {
    background-color: white;
}

.title_opt {
    background-color: #dfdfdff1;
    font-weight: 600 !important;
    border-radius: 0;
    padding: 20px;
    font-size: 23px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #14222d;
}

.content_oneitemPage {
    padding: 20px;
}

.title_opt_metaData {
    background-color: #f1f1f1;
    border-radius: 0;
    margin-top: 30px;
    font: 24px arial;
    color: #14222d;
    padding: 10px;
}

.table_meta_data {
    width: 100%;
    padding: 10px 25px 10px 25px;
    background-color: #F7F8F9;
}

.table_meta_data input {
    background-color: #F7F8F9;
}

.meta_data {
    display: flex;
    flex-wrap: wrap;
}

.input_date {
    width: calc(var(--width_container) * 0.15);
    margin-right: 20px;
}

.input_time {
    width: calc(var(--width_container) * 0.15);
}

.reload_page {
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    visibility: hidden;
    opacity: 0;
    overflow: none;
    z-index: 25;
    background-color: #0000002c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reload_page::before {
    background-color: rgba(0, 0, 0, 0.536);
    border-radius: 50%;
    content: '';
    background-image: var(--reload);
    background-repeat: no-repeat;
    background-position: center;
    background-size: var(--images_size);
    width: calc(var(--images_box) + 40px);
    height: calc(var(--images_box) + 40px);
    animation: reload .8s linear infinite;
}

@keyframes reload {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

#createAd {
    margin: 10px;
}

.actions:hover {
    background-color: #F7F8F9;
    cursor: pointer;
}

.darkFilter {
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 999;
    background: rgba(0, 0, 0, 0.65)
}

.tab {
    width: 180px;
    height: 50px;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #485965;
    color: #ebeaea;
    font-size: 19px;
    border: 2px solid #ebeaea;
    margin: 0 0 20px 0;
}

.tab:hover {
    cursor: pointer;
    color: #ffffff;
    background-color: #7aade4;
    border: 2px solid #7aade4;
}


._error, .parentUpload {
    outline: 3px solid rgb(255, 114, 114);
}

.accessTypes {
    font-size: 14px;
    padding: 10px 50px 0;
    display: block;
    text-align: center;
}

.popup {
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    min-height: 100%;
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow: none;
    z-index: 100;
    background: rgba(0, 0, 0, .6);
}

.popup_body {
    width: 250px;
    height: 250px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -125px 0 0 -125px;
    z-index: 101;
}

.popup_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.popup_stop {
    position: absolute;
    width: 77px;
    height: 77px;
    z-index: 103;
    top: 38px;
    left: 86px;
    cursor: pointer;
    z-index: 121;
}

.popup_stop:hover {
    box-shadow: 0 0 15px black;
    border-radius: 50%;
}

.popup_image {
    position: relative;
    top: -4px;
    left: 0px;
    z-index: 120;
    margin-bottom: 20px;
    width: 160px;
    height: 160px;
    animation: 2s linear 0s normal none infinite running rotate;
    -webkit-animation: 2s linear 0s normal none infinite running rotate;
}

.popup_image_ {
    position: absolute;
    top: 9px;
    left: 58px;
    z-index: 120;
    margin-bottom: 20px;
    width: 130px;
    height: 130px;
    animation: 2s linear 0s normal none infinite running rotate_2;
    -webkit-animation: 2s linear 0s normal none infinite running rotate_2;
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotate_2 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes rotate_2 {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.popup_desc {
    color: white;
    font-size: 20px;
    text-align: center;
    vertical-align: middle;
}

.percentageOfProgress {
    z-index: 110;
    position: absolute;
    top: 140px;
    left: 180px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    font-size: 26px;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    color: #ffffff;
    /* Цвет текста, совпадает с цветом фона */
    text-shadow: #333 -2px -2px 2px;

}

.round {
    position: absolute;
    top: -18px;
    left: 30px;
}

.progressBar svg {
    width: 200px;
    height: 200px;

}

.bg {
    fill: rgba(0, 0, 0, .3);
    stroke-width: 10px;
    stroke: #575757;
}

.progress {
    fill: none;
    stroke-width: 6px;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    stroke-dasharray: 342;
    stroke-dashoffset: 342;
    stroke: rgb(255, 255, 255);
}

.back_view {
    display: none;
    background-color: rgba(0, 0, 0, 0.742);
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}

.left_btn {
    cursor: pointer;
    position: fixed;
    top: 50%;
    left: 24%;
    text-align: center;
    width: 60px;
    height: 60px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    border-radius: 50%;
    background-image: url(../assets/images/arrow.png);
    background-repeat: no-repeat;
    border: 4px solid rgb(218, 218, 218);
    background-size: cover;
}

.left_btn:hover {
    background-color: rgb(221, 221, 221);
}

.right_btn {
    cursor: pointer;
    position: fixed;
    top: 50%;
    right: 24%;
    width: 60px;
    height: 60px;
    background-color: rgb(255, 255, 255);
    -webkit-box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    box-shadow: 0px 4px 9px 2px rgba(34, 60, 80, 0.2);
    border-radius: 50%;
    background-image: url(../assets/images/arrow.png);
    background-repeat: no-repeat;
    border: 4px solid rgb(218, 218, 218);
    background-size: cover;
    transform: rotate(180deg);
}

.right_btn:hover {
    background-color: rgb(221, 221, 221);
}

.disabled_btn {
    cursor: default;
    background-color: rgb(128, 128, 128);
    border-color: rgb(128, 128, 128);
}

.cancel_btn {
    position: fixed;
    top: 25px;
    right: 30.2%;
    width: 30px;
    height: 30px;
    z-index: 12;
    padding-bottom: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: rgb(219, 0, 0);
    border: 1px solid rgb(255, 255, 255);
    font-size: 22px;
    color: white;
}

.cancel_btn:hover {
    background-color: rgb(168, 0, 0);
}

.meta_btn {
    border: 0;
    display: none;
    opacity: 0;
    cursor: pointer;
    position: relative;
    top: 25px;
    right: -23.5%;
    background-color: rgb(105, 105, 105);
    border: 1px solid rgb(255, 255, 255);
    width: auto;
    height: 30px;
    z-index: 13;
    font-size: 19px;
    padding: 0 4px 0 4px;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white
}

.meta_btn:hover {
    background-color: rgb(184, 184, 184);
}

.meta_content {
    transition: all ease 0.5s;
    display: none;
    opacity: 0;
    width: 400px;
    min-height: 400px;
    max-height: 500px;
    position: relative;
    top: 25px;
    right: 0%;
    z-index: 12;
    background-color: rgba(255, 255, 255, 0.94);
    border-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    -webkit-box-shadow: -5px 5px 5px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: -5px 5px 5px -5px rgba(34, 60, 80, 0.6);
    box-shadow: -5px 5px 5px -5px rgba(34, 60, 80, 0.6);
}

.meta_content::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.94);
    width: 57px;
    height: 33px;
    position: absolute;
    top: 0px;
    right: -51px;
    z-index: 5;
    -webkit-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
    -moz-box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
    box-shadow: 0 5px 5px -5px rgba(34, 60, 80, 0.6);
}

.ins_item {
    background-color: #00779b;
    text-shadow: 1px 1px 1px black;
    opacity: .7;
    color: white;
    font-size: 20px;
    padding-left: 15px;
    min-height: 30px;
    margin: 7px 15px 7px 15px;
    display: flex;
    align-items: center;
}

.descPage_container {
    min-height: 50px;
    max-height: 200px;
    margin: 15px 15px 7px 15px;
    color: rgb(66, 66, 66);
    overflow-y: auto;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 18px;
}

.keywords_container {
    display: flex;
    align-items: left;
    justify-content: left;
    flex-wrap: wrap;
    margin: 15px 15px 7px 15px;
}

.keyWord_item {
    margin-bottom: 7px;
    margin-right: 10px;
    text-shadow: 1px 1px 1px rgb(0, 0, 0);
    background-color: #00a3d4;
    color: white;
    padding: 3px 5px 3px 5px;
    flex-basis: 10%;
    font-size: 15px;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
}





.messages_wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.message_container {
    flex: content;
    margin-right: 30px;
    width: 100%;
    min-height: 40px;
    display: flex;
    border: 1px solid gray;
    margin-bottom: 15px;
    padding: 5px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.582);
    position: relative;
    padding-right: 15px;
}

.message_container:hover {
    outline: 3px solid black;
}

.logo_user {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 25px;
    text-transform: uppercase;
    color: white;
    margin-right: 20px;
    margin-left: 5px;
    margin-top: 5px;
    min-height: 40px;
    min-width: 40px;
    user-select: none;
}

.message_block {
    width: 100%;
}

.name {
    font-size: 22px;
    font-family: Arial, Helvetica, sans-serif
}

.identificator {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 18px !important;
    color: rgb(0, 0, 0) !important;
}

.name span {
    margin-left: 30px;
    font-size: 14px;
    color: gray;
}

.message {
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0px 0px 3px black inset;
    background-color: #ebebeb;
    font-size: 20px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    width: fit-content;
}

.message_container a {
    color: rgb(0, 68, 255);
}

.email {
    float: right;
    padding: 5px 10px 5px 0;
    font-weight: 600;
}

.message_container .delete {
    cursor: pointer;
    position: absolute;
    top: -12px;
    right: -10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
    border: 2px solid rgb(0, 0, 0);
    width: 20px;
    height: 20px;
    transition: 1s;
}

.message_container .delete:hover {
    transform: rotateZ(360deg);
}

.message_container .delete:before, .message_container .delete:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 3px;
    background: rgb(0, 0, 0);
    transition: 1s;
    top: 6px;
    left: 0px;
}

.message_container .delete:before {
    transform: rotate(45deg);
}

.message_container .delete:after {
    transform: rotate(-45deg);
}

.message_container .delete:hover:after,
.message_container .delete:hover:before {
    background: black;
}

.delPartitionsViewWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.582);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.delPartitionsViewModal {
    min-width: 500px;
    min-height: 200px;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.delPartitionsTitleModal {
    width: 100%;
    padding: 3px 10px;
    font-weight: 600;
    font-size: 22px;
    background-color: #bb0d07;
    color: white;
}

.delPartitionsCheckModal {
    padding: 10px;
    height: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.radioModal {
    font-size: 18px;
    height: 100%;
}

.radioModal div {
    padding-bottom: 10px;
    height: 100%;
}

.radioModal div label {
    white-space: pre-wrap;
    word-break: break-word;
    word-wrap: break-word;
}

.buttonsModal {
    width: fit-content;
    height: 100%;
    margin-left: auto;
    margin-right: 0;
    margin-top: 20px;
}

.buttonsModal button {
    border: 0;
    outline: none;
    color: white;
    padding: 5px 8px;
    font-size: 22px;
    cursor: pointer;
}

.buttonsModal button:first-child {
    margin-right: 10px;
    background-color: #1bb430;
}

.buttonsModal button:last-child {
    background-color: #df2424;
}

.stateVideoNews {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: fit-content;
    padding-bottom: 23px;
}

.stateVideoNews div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding-left: 10px;
    flex-wrap: nowrap;
    white-space: nowrap;
    margin: 0 !important;
}

.search_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.search_title button {
    background-image: linear-gradient(to bottom, #ff9c9c, rgb(224, 0, 0));
    font-weight: 600;
    font-family: Roboto, sans-serif;
    font-size: 21px;
    color: white;
    border: 0;
    outline: none;
    cursor: pointer;
}

.search_title button:hover {
    background-image: linear-gradient(to bottom, #ff0000, rgb(175, 0, 0));
}

.popupResponse {
    position: fixed;
    top: 40px;
    width: 250px;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 7px 1px #0c0c0c38;
    border: 2px solid rgb(122, 122, 122);
    cursor: pointer;

    z-index: -1;
    right: -100%;
    opacity: 0;
    transition: all ease-in-out .4s;
}

.popupResponse:hover {
    filter: brightness(90%);
}

.popupResponse,
.popupResponse * {
    border-radius: 0;
}

.popup_visible {
    opacity: 1;
    z-index: 100;
    right: 40px;
}

.popupResponse[data-error='-1'] .popupHeadStatus {
    background-color: #1bca47;

}

.popupResponse[data-error='0'] .popupHeadStatus {
    background-color: rgb(134, 134, 134);
}

.popupResponse[data-error='1'] .popupHeadStatus {
    background-color: rgb(255, 0, 0);
}


.popupHeadStatus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

}

.popupTitle {
    padding: 5px 5px;
    white-space: pre-wrap;
    font-size: 18px;
}

.popupStatus {
    padding: 5px 15px;
    font-weight: 700;
    text-decoration: underline;
}

.popupBodyMessage {
    padding: 5px;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    border-top: 2px solid rgb(122, 122, 122);
    height: 100%;
    background-color: white;
    font-weight: 600;
    text-align: center;
}

.popupMessage {
    height: 100%;
}

.row_selectors {
    display: flex;
    gap: 10px;
}

.row_selectors>div {
    width: fit-content;
}

.ZennoLabContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    width: 100%;
    height: fit-content;
    padding: 10px;
    gap: 15px;
}

.ZennoLabElement {
    height: 300px;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.ZennoLabElement .id {
    position: absolute;
    z-index: 5;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    padding: 5px;
    font-weight: 600;
    left: 10px;
    top: 10px;
}

.ZennoLabElement .mediacont {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: rgb(235, 235, 235);
    animation: glass 1s ease-in-out infinite;
}

.ZennoLabElement .media {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

@keyframes glass {
    0%, 100% {
        background-color: white;
    }

    50% {
        background-color: rgb(216, 216, 216);
    }
}


.ZennoLabElement .mediacont .link {
    word-break: break-all;
    word-wrap: break-word;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: fit-content;
    padding: 5px 10px;
    background-color: rgba(94, 94, 94, 0.5);
    backdrop-filter: blur(30px);
    border-radius: 0;
    color: white;
    text-shadow: 1px 1px 1px black;
    font-weight: 600;
}


.deleteZennoLabElement, .editZennoLabElement {
    position: absolute;
    right: 5px;
    z-index: 2;
    backdrop-filter: blur(20px);
    background-color: rgb(78, 78, 78);
    border: 4px solid rgb(78, 78, 78);
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.deleteZennoLabElement {
    top: 5px;
}

.editZennoLabElement {
    top: 45px;
    font-size: 20px;
    text-align: center;
    margin: 0;
    padding: 0 0 4px 0;
}

.editZennoLabElement:hover,
.deleteZennoLabElement:hover {
    transform: scale(1.2);
}

.deleteZennoLabElement span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: white;
    position: absolute;
}

.deleteZennoLabElement span:nth-child(1) {
    transform: rotate(-45deg);
}

.deleteZennoLabElement span:nth-child(2) {
    transform: rotate(45deg);
}


.type_opt {
    width: 98%;
    padding: 10px 20px;
    margin: 10px auto 0 auto;
    font-size: 20px;
    background-color: rgb(236, 236, 236);
    border-radius: 0;
    display: flex;
    flex-flow: row nowrap;
}

.type_opt .reload {
    width: 30px;
    height: 30px;
    background-color: black;
    margin: 0 0 0 auto;
    mask-size: contain;
    mask-repeat: no-repeat;
    mask-position: center;
    mask-image: url(../assets/images/reload.png);
    transition: .5s;
    cursor: pointer;
}

.type_opt .reload:hover {
    transform: rotate(360deg);
}