/* keywords module */

.keyword_container {
    position: relative;
    display: flex;
    flex-direction: row;
    background-color: #355566;
    display: flex;
    gap: 10px;
}

.keyword_container:hover {
    background-color: #698797;
}

.keywords_element {
    color: white;
    border-radius: 0;
    font-size: 18px;
    height: 100%;
    padding: 7px 12px;
}

.keyword_container span {
    position: relative;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    min-width: 20px;
    height: 20px;
    color: black;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    outline: 2px solid rgb(3, 170, 192);
    box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, 0.651);
    text-align: center;
    z-index: 20;
    font-weight: 600;
}

.findedElement {
    width: fit-content;
}

.keywords_meta {
    padding: 0 20px;
    background-color: rgb(241, 241, 241);
    flex-direction: column;
    gap: 5px;
    display: flex;
    height: 0;
    overflow: hidden;
    transition: all 0.2s;
    margin: 10px 0 10px 0;
}

.keywords_view_btn {
    width: 100%;
    border: 0;
    outline: none;
    background-image: linear-gradient(to bottom, #f1f1f1, #dbdbdb);
    cursor: pointer;
    height: 30px;
    font-size: 16px;
    font-weight: 600;
}

.keywords_view_btn:hover {
    background-image: linear-gradient(to bottom, #e4e4e4, rgb(189, 189, 189));
}

.keywords_meta_view {
    padding: 10px 20px;
    height: auto;
}

.keywords_title {
    font-weight: 600;
    padding: 5px 0 0 0;
    font-size: 19px;
}

.keywords_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 5px;
}

.keywords_search_row {
    position: relative;
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin: 0 0 5px 0;
    overflow-y: hidden;
    width: 100%;
}

.keywords_search_row::-webkit-scrollbar {
    width: 0;
    height: 0;
}
