.range_tablewithitems {
    display: flex;
    flex-flow: column nowrap;
    gap: 15px;
    padding: 5px 10px;
    width: 100%;
    background-image: linear-gradient(to bottom, #F1F1F1, #eeeeee);
    border-radius: 0;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.range_tablewithitems .row {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 10px;
}

.range_tablewithitems .row .title {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 10px;
    font-weight: 600;
    z-index: 10;
}

.range_tablewithitems .row .title::before {
    content: '';
    width: 10px;
    height: 1px;
    background-color: #00000025;
    display: block;
    position: relative;
}

.range_tablewithitems .row .title::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #00000025;
    display: block;
    position: relative;
}

.child_range {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 35px;
}

.child_range div {
    color: rgb(73, 73, 73);
    padding: 0 5px;
}

.child_range select {
    margin: 0;
    padding: 5px;
    height: 100%;
    width: 155px;
    border-radius: 0;
}

.child_range button {
    font-size: 16px;
    height: 100%;
    padding: 5px;
    outline: none;
    box-shadow: 0 1px 0 1px rgb(0 0 0 / 4%);
    border: 1px solid #aaa;
    color: #000000;
    background-color: #fff;
    background-image: linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    height: 100%;
    cursor: pointer;
    transition: .3s;
    min-width: 25px;
}

.child_range button:hover {
    border: 1px solid #000000;
}

.child_range select.lengthPagination {
    width: 125px !important;
}

.child_range .buttonsUpDown {
    background-image: linear-gradient(to bottom, #ececec 0%, #dadada 100%);
}

.child_range .reload {
    width: 35px;
    background-image: var(--i_reload), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.child_range .clear {
    width: 35px;
    background-image: var(--i_eraser), linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.child_params {
    font-size: 18px;
    font-weight: 600;
}

.calendar_wrapper {
    position: fixed;
    right: 40px;
    top: 20px;
    display: flex;
    flex-flow: column nowrap;
    gap: 10px;
    width: calc(((100% - 55.5% - 30px) / 2.5));
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 3px 15px 2px rgba(90, 90, 90, 0.3);
}

.calendar_wrapper .about_title {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    text-align: left;
    user-select: none;
}

.calendar_wrapper .calendars {
    display: flex;
    flex-flow: column nowrap;
    gap: 10px
}

.calendar_wrapper .calendarTitle {
    position: relative;
    z-index: 3;
    border-radius: 0;
    width: 100%;
    height: fit-content;
    background-color: #4084D1;
    color: white;
    text-align: center;
    padding: 7px 0;
    font-size: 18px;
    font-weight: 700;
    user-select: none;
}

.customCalendar .calendar {
    width: 100%;
}

.customCalendar.active .calendar::before {
    content: '';
    width: 30px;
    height: 30px;
    background-color: white;
    position: absolute;
    left: -13px;
    top: 52px;
    transform: translateY(-50%) rotate(45deg);
    z-index: -1;
}

.wrapperSetterDay {
    position: relative;
    padding: 0 10px;
}

.wrapperSetterDay .arrow {
    position: absolute;
    background-color: transparent;
    top: 50%;
    border-left: 3px solid #4084D1;
    border-bottom: 3px solid #4084D1;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 0;
    cursor: pointer;
}

.wrapperSetterDay .arrow:hover {
    border-color: #000000;
}

.wrapperSetterDay .next {
    left: 3px;
    transform: translate(-50%, -5px) rotate(45deg);
}

.wrapperSetterDay .prevent {
    right: -6px;
    transform: translate(-50%, -5px) rotate(225deg);
}

.setterDay {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
    gap: 2px;
    border-radius: 0;
}

.setterDay div {
    border: 2px solid #0088E2;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(0, 0, 0);
    padding: 3px 5px;
    cursor: pointer;
    border-radius: 0;
    transition: .1s;
}

.setterDay .active,
.setterDay div:hover {
    background-color: #0088E2;
    color: white;
}

.setterDay::-webkit-scrollbar {
    width: 12px;
}

.setterDay::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}

.setterDay::-webkit-scrollbar-thumb {
    background-color: #4084D1;
    border: 3px solid rgb(255, 255, 255);
    border-right: none;
    border-left: none;
}