@keyframes showpartitions {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

$c_active: #90a5c2;
$c_innactive: #000;
$c_for_edit: #215399;

$c_disabled: #ff0000;
$c_enabled: #20c01a;

.partitions_wrapper {
    position: relative;
}

.top_pw {
    margin-bottom: 10px;
}

.edit_buttons {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: fit-content;
    gap: 10px;
}

.edit_buttons div:has(select),
.edit_buttons div:has(select) select {
    height: fit-content;
    margin: 0;
}

.pe_btn {
    height: 30px;
    width: 30px;
    border: none;
    color: white;
    cursor: pointer;
    mask-size: cover;
    mask-position: center;
    mask-repeat: no-repeat;
    position: relative;
}

.pe_btn:hover {
    background-color: #6d6d6d;
}

.pe_edit_btn {
    background-color: #000000;
    mask-image: var(--i_pencil);
}

.pe_restore_btn {
    background-color: #000000;
    mask-image: var(--i_recovery);
}

.pe_create_btn {
    background-color: #000000;
    mask-image: var(--i_add);
}

.pe_save_cancel {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.pe_save_cancel div:hover .pe_back {
    background-color: white;
}

.pe_cancel_btn {
    mask-size: 85%;
    mask-position: 2px 2px;
    background-color: white;
    mask-image: var(--i_x-mark);
}

.pe_save_btn {
    mask-size: 85%;
    mask-position: 2px 2px;
    background-color: white;
    mask-image: var(--i_checkmark3);
}

.pe_wrapper_btn_cs {
    position: relative;
}

.pe_wrapper_btn_cs:has(.pe_save_btn) .pe_back {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00cc11;
    border-radius: 50%;
}

.pe_wrapper_btn_cs:has(.pe_cancel_btn) .pe_back {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #f80000;
    border-radius: 50%;
}

.partitions_block {
    display: flex;
    flex-wrap: wrap;
    gap: 3px;
    background-color: #e0e0e0;
    padding: 10px;
}

.pe_active {
    background-color: #7c7c7c;
    transform: scale(1.2);
}

.pe_wrapper {
    position: relative;
}

.pne_length {
    position: absolute;
    right: 0;
    top: 0;
    background-color: rgb(82, 82, 82);
    color: white;
    padding: 0 4px;
    font-size: 13px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
}

.partition_elem {
    border-radius: 0;
    height: fit-content;
    width: fit-content;
    background-color: #2153996b;
    color: white;
    padding: 12px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    max-width: 100%;
    cursor: default;
    user-select: none;
}

.partition_elem[data-count="0"],
.partition_elem[data-active="0"] {
    background-color: black;
}

.partition_elem_editable[data-active="0"]:hover {
    background-color: rgba(0, 0, 0, 0.719);
}

.partition_elem[data-active="0"].pe_deleted_partition {
    background-color: rgb(32, 192, 26) !important;
}

.pe_deleted_partition {
    background-color: red !important;
}

.partition_elem_editable {
    background-color: #215399;
    cursor: pointer;
}

.partition_elem_editable:hover {
    background-color: #2f72cf;
}

.partition_elem_editable:active {
    background-color: #3580e9;
}

.partition_elem_editable:focus {
    background-color: #ffffff;
    color: #000000;
}

.pe_info_btn {
    display: block;
    background-color: gray;
    mask-image: var(--i_info);
    mask-position: center;
    mask-size: cover;
    mask-repeat: no-repeat;
    width: 15px;
    height: 15px;
    cursor: pointer;
    transition: 0.3s;
}

.pe_info_btn:hover {
    background-color: #292929;
}

.info_description {
    display: flex;
    height: 0;
    visibility: hidden;
    opacity: 0;
    flex-direction: column;
    gap: 8px;
    font-family: Roboto, sans-serif;
    text-indent: 10px;
    font-size: 17px;
    color: #000000;
    background-color: #e0e0e0;
    padding: 0;
    border-radius: 20px;
    margin-top: 0;
    transition: 0.1s;
}
.insc {
    padding-bottom: 10px;
}
.info_isInfo {
    display: flex;
    height: auto;
    visibility: visible;
    opacity: 1;
    margin-top: 10px;
    padding: 10px;
}

.info_wrapper {
    display: flex;
    justify-content: left;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    gap: 10px;
}

.info_child {
    display: flex;
    flex-direction: row;
    gap: 4px;
}

.info_color {
    display: block;
    width: 20px;
    height: 20px;
}

.partition_create {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    opacity: 0;
    animation: 0.2s showpartitions ease-in-out forwards;
    padding-top: 10px;
}

.partition_create input {
    margin-bottom: 0 !important;
}

.partition_create_button {
    height: 30px;
    position: relative;
    top: 42px;
    margin-left: 10px;
    font-size: 20px;
    border: none;
    color: white;
    cursor: pointer;
    background-color: #2aac51;
}

.partition_create_button:hover {
    background-color: #37e26b;
}

.pe_save {
    color: #f0f0f0;
    font-weight: 700;
    animation: glow 3s ease forwards;
}

@keyframes glow {
    from {
        color: #f0f0f0;
    }

    25% {
        color: #6d6d6d;
    }

    50% {
        color: #ebebeb;
    }

    75% {
        color: #2b2b2b;
    }

    to {
        color: #000000;
    }
}

/* subpartitions */

.subpartitions_container {
    position: absolute;
    left: 0;

    height: 100%;
    width: fit-content;

    &[data-is_view="true"] {
        width: 100%;
        .subpartitions_title {
            writing-mode: horizontal-tb;
            height: fit-content;
            min-width: 50px;
            transform: none;
            font-size: 20px;
            top: 0;
            background: #ff0000;
            z-index: 12;
            &:hover {
                transform: none;
                background: #ff5656;
            }
        }
    }

    .subpartitions_title {
        position: absolute;

        left: 0;
        top: calc(50% - 1px);
        transform: translateY(-50%);
        writing-mode: vertical-lr;
        user-select: none;
        background: #000000;
        height: calc(100% - 1px);
        border-radius: 0;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        color: white;
        cursor: pointer;
        z-index: 2;
        &:hover {
            background: #474747;
        }
    }

    .subpartitions_full_container {
        position: absolute;
        z-index: 3;
        width: max-content;
        height: max-content;
        max-width: 1300px;
        min-width: 800px;
        background: #ffffff;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.35);
        padding: 40px 20px 20px 20px;
        border-radius: 0;

        .subpartitions {
            .subpartition {
            }
        }
    }
}

.error {
    position: absolute;
    left: 50%;
    top: calc(-100% - 40px);
    transform: translate(-50%, 0);
    background-color: red;
    width: max-content;
    max-width: 300px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;

    z-index: 20;
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 18px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);

    &::after {
        content: "";
        background-color: red;
        width: 15px;
        height: 15px;
        position: absolute;
        left: 50%;
        bottom: -8px;
        transform: rotate(45deg) translate(-8px, 8px);
    }
}

.partition_elem {
    &[data-error="true"] {
        outline: 3px solid red;
        min-width: 100px;
        transform: scale(1.2);
        z-index: 10;
        position: relative;
        box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.3);
    }
}
